import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import { CircularProgress, Link, Button, MenuItem, Stack, InputLabel, Select, FormControl, Paper } from '@mui/material';

import CodeMirror from '@uiw/react-codemirror';

import data from './data/examples.json';

const color = '#609c5c';

class App extends Component {
  constructor(props) {
    super(props);
     this.state = {
        example: 0,
        queryExampleNames: data.examples.map(example => example.title),
        queryExmaples: data.examples.map(example => example.query),
        rulesExamples: data.examples.map(example => example.rules.join("\n")),
        query: '',
        ruleset: '',
        result: '',
        buttonDisabled: false
      }
  }

  runQuery() {
    var config = {
      method: 'POST',
      url: '/api/query',
      headers: {
        'Content-Type': 'application/json',
      },
      data : {query: this.state.query, ruleset: this.state.ruleset}
    };

    var resultMessage = ''

    this.setState({buttonDisabled: true}, () => {
    axios(config)
    .then((response) => {
      var resultSplits = response.data.split('\n');
      resultSplits = resultSplits.filter(data =>
        !(data == "" || data.includes('(c) 2008 Thomas Neumann') || data.includes('Parsing') || data.includes('/tmp') || data.includes('Building the dictionary...') || data.includes("RDF-3X turtle importer")))
      resultMessage = resultSplits.join('\n');
    }

    )
    .catch(function(error) {
       console.log(error);
       resultMessage = error.message;
    }).finally(() => this.setState({result: resultMessage, buttonDisabled: false})  )});
  }

  componentDidMount() {
     this.setState({query : this.state.queryExmaples[0], ruleset: this.state.rulesExamples[0]}, () => this.runQuery());
  }

  render() {
    return (
        <div className='App'><Stack style={{marginLeft: 5, marginTop: 20, marginBottom: 0}} direction="row" spacing={5}>
          {/* Header */}
          <div style={{fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: 200, fontSize: 25, color:color}} >SPARQL Interface  🐢</div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small" style={{color: color}}>Examples</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={this.state.example}
              label="Examples"
              sx={{

                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
              }}
              onChange={(event) => { this.setState({example: event.target.value, query: this.state.queryExmaples[event.target.value], ruleset: this.state.rulesExamples[event.target.value]}, () => this.runQuery()) }}
            >
              {this.state.queryExampleNames.map((name, index) => {
                return <MenuItem key={index} value={index}>{name}</MenuItem>
              })}
            </Select>
          </FormControl>
          </Stack>
         {/* Rules */}
         <div style={{fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: 400, fontSize: 15, color:color, marginLeft: 5, marginBottom: 5}} >Rules</div>
          <Paper style={{display: 'flex',maxHeight: '50vh', overflow: 'auto'}}>
          <CodeMirror
            value={this.state.ruleset}
            options={{
              theme: 'monokai',
              tabSize: 1,
              keyMap: 'sublime',
              mode: 'jsx',
            }}
            onChange={(newValue) => { this.setState({ruleset: newValue})}}
            style={{ width: 'calc(100% - 20px' , margin: '10px', border: 0 }}
          />
         </Paper>
         {/* Query */}
         <div style={{fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: 400, fontSize: 15, color:color, marginTop: 15, marginLeft: 5, marginBottom: 5}} >Query</div>
          <Paper style={{display: 'flex', flexDirection: 'column-reverse', maxHeight: '50vh', overflow: 'auto'}}>
          <CodeMirror
            value={this.state.query}
            options={{
              theme: 'monokai',
              tabSize: 1,
              keyMap: 'sublime',
              mode: 'jsx',
            }}
            onChange={(newValue) => { this.setState({query: newValue})}}
            style={{ width: 'calc(100% - 20px' , margin: '10px', border: 0 }}
          />
         </Paper>
          <Stack style={{margin: '10px'}} direction="row" spacing={2}>
             {/* Execute Button */}
             <Button style={{borderColor: color, color: color}} variant="outlined" disabled={this.state.buttonDisabled} onClick={() => this.runQuery()}>Execute</Button>
             {this.state.buttonDisabled ? <CircularProgress style={{'color': color}} size="2rem"/> : ''}
          </Stack>
          {/* Result */}
          <Paper  style={{whiteSpace: 'pre', minHeight: '40px', overflow: 'auto', padding: '10px'}}>
            <div style={{fontFamily: 'monospace'}}>
              {this.state.result}
            </div>
          </Paper>
          {/* Footer */ }
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}>RDF system by Thomas Neumann</div>
          </Stack>
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}>Hosted by TUM,</div><Link href="https://www.db.in.tum.de/" color="inherit">Chair for Database Systems</Link>
          </Stack>
          <Stack style={{margin: '10px', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', fontSize: 15, color:color}} direction="row">
            <div style={{marginRight: '5px'}}>Maintainer:</div><Link href="mailto:i3erdb@in.tum.de" color="inherit">i3erdb@in.tum.de</Link>
          </Stack>
        </div>
    );
  }
}
export default App;
